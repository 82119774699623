<template>
  <div class="List">
    <van-nav-bar title="即将到期" @click-left="onClickLeft">
      <template #right>
        <span @click="onSerachClick">查询</span>
      </template>
    </van-nav-bar>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        class="list-box"
      >
        <CaseItem
          v-for="(item, index) in list"
          :item="item"
          :key="index"
          :isAdmin="userBaseInfo.resOperatorDTO.isAdmin"
          :userInfo="userBaseInfo.resOperatorDTO"
          class="item"
          @actionClick="onActionClick"
        ></CaseItem>
      </van-list>
    </van-pull-refresh>
    <TabBarNav></TabBarNav>
    <PopupForm v-model="showPopup" :actionType="actionType" :actionItem="actionItem" @confirmPopup="confirmPopup">
    </PopupForm>
  </div>
</template>

<script>
import { Search } from "vant";
import { Icon } from "vant";
import { Tabbar, TabbarItem } from "vant";
import popupFormMixin from '@/mixins/popupFormMixin';
import Vue from "vue";
Vue.use(Search, Icon);
Vue.use(Tabbar);
Vue.use(TabbarItem);
import Api from "@/api/caseHandle";

import { Dialog, Toast } from "vant";
export default {
  mixins: [popupFormMixin],
  props: {},
  components: {},
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,

      filter: {
        statusEq: "",
        keyLike: "",
        caseType: "overdue",
      },
      pageNo: 1,
      pageSize: 10,
    };
  },
  methods: {
    handelTab() {
      this.onRefresh();
    },
    onSearch() {
      this.pageNo = 1;
      this.list = [];
      this.onLoad();
    },
    onSerachClick() {
      this.$router.push({
        name: "search",
      });
    },
    async onLoad() {
      try {
        this.finished = false;
        this.loading = true;
        const { pageNo, pageSize, filter } = this;
        // 去除空项
        for (const key in filter) {
          if (filter[key] === "" || filter[key] === null) {
            delete filter[key];
          }
        }
        var res = await Api.findPageISO({
          pageNo,
          pageSize,
          ...filter,
        });
        const { data } = res;
        const { list } = data;
        if (this.pageNo == 1) {
          this.list = list;
        } else {
          this.list = [...this.list, ...list];
        }
        this.finished = !data.isNextPage;
        this.pageNo++;
      } catch (error) {
        Toast.fail(error);
        this.list = [];
        this.finished = true;
      } finally {
        this.loading = false;
        this.refreshing = false;
      }
    },
    onRefresh() {
      this.pageNo = 1;
      this.onLoad();
    },

    // 反馈
    goFeedBack(item) {
      this.$router.push({
        name: "Settle",
        params: {
          homeNo: item.homeNo,
        },
      });
    },

    // 接单
    async receiveClick(item) {
      if (
        (await Dialog.confirm({
          title: "提示",
          message: "确认接单",
        })) !== "confirm"
      ) {
        return;
      }
      Toast.loading();
      try {
        const res = await Api.receive({
          homeNo: item.homeNo,
        });

        Toast.success(res.message);
        this.onRefresh();
      } catch (error) {
        Toast.fail(error);
      }
    },
  },
  mounted() {
    this.fetchEnums("getHomeStatusEnum");
  },
};
</script>

<style lang="less" scoped>
.List {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.van-tabs {
  /deep/ .van-tabs__line {
    background: #3c86ff !important;
    height: 2px !important;
    width: 4em !important;
  }
}

.list-box {
  padding: 10px 12px;
  .item {
    margin-bottom: 15px;
  }
}
</style>
